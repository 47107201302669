/*
 * Fonts
 * TODO: Set fonts properly.
 */
html *
{
   font-family: "Roboto", sans-serif;
   font-weight: 300;
}

.app {
  height: 100%;
}


/*
 * Background
 */
.static-background {
  background: #dbf1eb;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
}

/*
 * Nav
 */

.navbar img {
  width: 130px;
}

#nav-login {
  margin-top: 5px;

  background-color: #ff3366;
  color: white;
  border: none;
  font-weight: bold;
}

#nav-logout {
  margin-top: 5px;

  background-color: white;
  color: #ff3366;
  border: 1px solid #ff3366;
  font-weight: thin;
}

.superMenu {
  margin-top: 7px;
}

.container .nav-item .nav-link {
  padding-right: 10px;
  padding-left: 10px;
  padding-top: 10px;
  margin-right: 5px;
  margin-left: 5px;
}

.navbar-nav a.nav-link.main {
  background: #ff3366;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  color: white !important;
}

.nav-link.selected {
  border-bottom: 1px solid #d2d2d2;
}

/*
 * Modal
 */

.popup-close {
  float: right;
  margin-top: -18px;
  margin-right: -12px;
  cursor: pointer;

  color: gray;
  font-size: 15px;
  font-weight: bold;
}

.popup-body {
  padding: 5px;
}


/*
 * Plans section
 */

 .plans {
  max-width: 1000px !important;
 }

.plans-title {
  margin-top: 80px;
  font-size: 45px;
  color: #212529;
}

.plan-item {
  border: solid 1px #d2d2d2;
  min-height: 390px;
  max-width: 300px;
  background-color: #f2f3f54f;
  border-radius: 5px;
}

.plan-item .list-group-item {
  background-color: #f2f3f54f;
}

.plan-item .list-group-item:first-child.noborder {
  border-radius: 5px;
}

.plan-icon {
  width: 70px;
}

.plan-item .name {
  font-family: "Roboto", sans-serif;
  color: #212529;
  font-size: 24px;
  font-weight: bolder;
}

.name.corporate{
  font-size: 20px;
}

.list-group.plan-item {
  margin-top: 50px;
}

.plan-item .extra-video {
  font-size: 12px;
}

.plan-item .price {
  font-size: 18px;
  color: #212529;
  height: 45px;
  margin-top: 20px;
}

.plan-item .description {
  height: 50px;
  margin-top: 20px;
  font-size: 18px;
}
.plan-item .description.corporate{
  height: auto;
  margin-top: 0px;
  font-size: 22px;
}

.plan-item .description .features {
  margin: 0 auto;
  margin-top: 15px;
  width: 150px;
  text-align: left;
  font-size: 14px;
}

.plan-item .description .features .views-plus {
  display: block;
  margin-top: -2px;
  margin-left: 55px;
  font-size: 9px;
}

.plan-item .description .features .views-professional {
  display: block;
  margin-top: -2px;
  margin-left: 62px;
  font-size: 9px;
}


.plan-item .btn {
  color: white;
  background-color: #2C2E2F;
  border-radius: 200px;
  padding: 10px 35px;
  width: 150px;
  margin-left: auto;
  margin-right: auto;
}

.plans-note {
  margin-top: 30px;
  padding-bottom: 70px;
  font-family: "Roboto", sans-serif;
  width: 100%;
  color: #495057;
  font-size: 12px;
}

.free-plan .btn {
  background-color: #2C2E2F;
  color: white;
}

.paypal-btn {
  width: 150px;
  max-width: 200px;
  margin: 0 auto;
  margin-top: 10px;
}

/*
 * Uploader
 */

.uploader {
  background-color: white;
  min-height: 200px;
}

.uploader.shadow {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.btnUpload.creating {
  color: gray;
  background: #e9ecef;
  border-color: gray;
  pointer-events: none;
}

.uploader .uploader-sub-msg {
  font-size: 12px;
  font-weight: lighter;
}

.uploader .backgroundVideo {
  height: 150px;
}

.uploader-select-video-msg {
  padding: 10px;
  text-align: center;

  font-size: 15px;
  font-family: "Roboto", sans-serif;
  font-weight: bold;
}

.upload-remaining-msg {
  width: 200px;
  max-width: 100%;
  font-size: 14px;
}

.upload-sucess-msg {
  min-width: 200px;
  max-width: 100%;
}

.upload-sucess-msg .title {
  margin-bottom: 10px;
  font-weight: bold;
  color: #495057;
  font-size: 18px;
}

.upload-sucess-msg .image-preview {
  width: 100%;
  padding: 20px 5px;
  background-color: #4d5761;
  background-color: #e9f7f4;
  border: solid 2px #92d5c3;
  border-radius: 4px;
}

.upload-sucess-msg .image-preview img {
  display: block;
  margin: auto;
  max-width: 100%;
}


.upload-sucess-msg .upload-sucess-msg-actions {
  margin-top: 5px;
  text-align: center;
}

.upload-sucess-msg .upload-sucess-msg-actions div {
  width: 50%;
  max-width: 150px;

  padding: 5px 10px;
  margin: 2px 5px;

  font-family: "Roboto", sans-serif;
  font-size: 12px;
  color: #0D0810;
  border: solid 1px #0D0810;
  border-radius: 10px;

  cursor: pointer;
}

.upload-sucess-msg .upload-sucess-msg-actions a {
  color: black;
  text-decoration: none;
}

.upload-sucess-msg .sub-msg {
  margin: 5px 0;
  text-align: center;
  font-size: 14px;
}

@media (max-width: 600px) {
  .upload-sucess-msg .upload-sucess-msg-actions div {
    width: 100%;
  }
}

.upload-progress {
  max-width: 90%;
  width: 600px;
  margin: auto;
}

.upload-progress .title {
  margin-bottom: 10px;
  font-size: 14px;
}

.upload-progress .progress {
  height: 5px;
}

.uploader-image-actions {
  font-size: 25px;
  color: #495057;
  text-align: center;
}

.uploader-image-actions div {
  margin: 0 10px;
  cursor: pointer;
  display: inline;
}

.uploader-selected-image {
  padding: 10px 5px;
  background-color: #e0f2ed;
  margin: 0 -20px 0 -15px;
  height: 200px;
  text-align: center;
}

.uploader-selected-image img {
  max-width: 100%;
  max-height: 180px;
}

.uploader-image-actions-msg {
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  display: block !important;
}

.uploader .list-group-item:first-child {
  border: 1px solid rgba(0,0,0,.125);
  border-bottom: none;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}

.uploader .list-group-item:last-child {
  border: 1px solid rgba(0,0,0,.125);
  border-top: none;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.uploader .list-group-item {
  border-bottom: none;
  border-top: none;
}

.download-app-links {
  width: 295px;
  max-width: 100%;
  text-align: center;
  margin: 50px auto;
}

@media (min-width: 768px) {
  .download-app-links {
    display: none;
  }
}

/*
 * Video List
 */

.videos-list {
  padding-top: 490px;
  margin-bottom: 100px;
}

.video-item {
  position: relative;
  margin-top: 40px;
}

.video-actions {
  display: none;
  position: absolute;

  width: 200px;
  left: 0;
  right: 0;
  bottom: 2px;
  margin: 0 auto;
  padding: 2px 5px;

  background-color: rgba(0, 0, 0, 0.50);
  border: solid 1px rgba(255, 255, 255, 0.10);
  border-radius: 5px;
}

.video-item:hover .video-actions {
  display: inline;
}

.video-actions div {
  display: inline-block;
  color: white;
  cursor: pointer;
}

.video-actions .play {
  font-size: 8px;
}

.video-actions .trash {
  float: right;
  margin-left: 10px;
  font-size: 12px;
}

.video-player-container {
  position: fixed;
  z-index: 8;
  width: 100%;
  height: 100%;
  background-color: black;
}

.video-js-responsive-container.vjs-hd {
    padding-top: 56.25%;
}
.video-js-responsive-container.vjs-sd {
    padding-top: 75%;
}
.video-js-responsive-container {
    width: 100%;
    position: relative;
    z-index: 8;
}
.video-js-responsive-container .video-js {
    height: 100% !important;
    width: 100% !important;
    position: absolute;
    top: 0;
    left: 0;
}

.video-player {
  margin: 10px auto;
  min-width: 600px;
  text-align: center;
}

.player {
  height: 430px;
  max-width: 100%;
}

.vjs-control-text {
  display: none !important;
}

.load-next-page {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
  cursor: pointer;
}

.loading-videos {
  width: 100%;
  text-align: center;
  font-size: 18px;
  margin-top: 20px;
}

.video-js {
  margin: 10px auto;
  min-width: 600px;
  text-align: center;
  height: 430px;
  max-width: 100%;
}

@media (max-width: 600px) {
  .video-js {
    min-width: 100%;
    margin: 10px auto;
    text-align: center;
    height: 250px;
    max-width: 100%;
  }

  .video-player-container {
    width: 100%;
    height: 100%;
  }

  .videos-list {
    padding-top: 300px;
  }
}

@media (max-height: 900px) {
  .plans-title  {
    margin-top: 10px;
  }
  .list-group.plan-item {
    margin-top: 20px;
  }
}

/* Login form */

.auth-form {
  position: absolute;
  margin-left: auto;
  margin-top: 150px;
  padding-top: 110px;

  width: 500px;
  max-width: 100%;

  background-color: white;
  border-radius: 2px;
}

.auth-form.login-form {
  height: 500px;
}

.auth-form.register-form {
  height: 550px;
}

.auth-form .title {
  font-size: 35px;
}

.login-input a, .register-input a {
  cursor: pointer;
}

#login-btn, #register-btn {
  cursor: pointer;
  background-color: #ff3366;
  color: white;
  font-weight: bold;
}

.activation-msg {
  font-size: 25px;
}

/* FAQs */

.faq-section {
  max-width: 600px;
  margin: 0 auto;
  margin-top: 100px;
  padding-bottom: 70px;

  text-align: justify;
}

.faq-title {
  font-size: 20px;
  font-weight: bold;
}

.faq-item {
  margin-top: 10px;
}

.faq-question {
  font-size: 16px;
  font-weight: bold;
}

.faq-answer {

}

/* Photographers sections */

.photographers-video {
  max-width: 100%;
  text-align: center;
  margin-bottom: 30px;
}

.photographers-video iframe {
  max-width: 100%;
  width: 600px;
  height: 350px;
}

/* Promo code */

.promo-apply {
  margin-top: 40px;
  margin-right: -5px;

  width: 100%;

  font-family: Gadugi;
}

.promo-apply input {
  padding-left: 10px;
  margin-bottom: 5px;

  height: 30px;
  max-width: 70%;

  border: 1px solid gray;
}

.promo-apply button {
  margin-left: 5px;

  width: 70px;
  height: 30px;

  border: 1px solid gray;

  background: white;
  color: black;
  line-height: 0.7em;
}

.promo {
  width: 400px;
  max-width: 100%;
  color: #212529;
}

.promo-title {
  font-size: 24px;
  text-align: center;
}

.promo-description {
  text-align: center;
  font-weight: bold;
  margin-top: 10px;
}

.promo-select-plan {
  width: 80%;
  margin: 0 auto;
  margin-top: 10px;
}

.promo-details {
  margin-top: 20px;
  font-size: 14px;
}

.promo-details .promo-row {
  margin: 10px 2px;
  padding: 5px 0;
  border-bottom: 1px dashed gray;
}

.promo-details .promo-row div {
  display: inline-block;
}

.promo-details .promo-row .name {
  width: 25%;
}

.promo-details .promo-row .desc {
  width: 50%;
  text-align: center;
}

.promo-details .promo-row .value {
  float: right;
}

.promo-paypal-btn {
  margin-top: 20px;
}

.promo-gift-btn {
  margin-top: 15px;
  text-align: center;
}

.promo-gift-btn button {
  color: white;
  background-color: black;
  border-radius: 300px;
  padding: 4px 35px;
  border: 1px solid black;

  cursor: pointer;
}

.promo-gift-btn button:hover{
  background-color:white;
  color:black;
  cursor: pointer;
}

/****/

.fa-request, .fa-request:hover {
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  color: black !important;
}

.try-for-free-btn, .try-for-free-btn:hover {
  cursor: pointer;
  font-weight: bold;
  color: #212529 !important;
}

.learn-more {
  display: inline-block;
  color: #212529;
}

.learn-more:hover {
  color: #212529;
  text-decoration: none;
}

.learn-more i {
  display: block;
  text-align: center;

  font-size: 24px;
  font-weight: bold;
}

.login-nav {
  height: 60px;
}

.remaining-count {
  position: absolute;
  text-align: center;
  font-size: 12px;
}

.remaining-count a {
  color: #212529;
}

.non-authenticated-message {
  margin-top: 200px;
  text-align: center;
  font-size: 20px;
}

.non-authenticated-message a {
  color: black;
  text-decoration: underline;
}

/*
 * My Collection
 */

.framealive-collection {
  width: 900px;
  max-width: 90%;
  margin: 0 auto;

  padding-top: 77px;
  margin-bottom: 100px;
}

.framealive-collection .loading {
  padding-top: 40%;
  text-align: center;
}

.framealive-actions {
  position: fixed;
  left: 0;
  z-index: 999;
  background-color: #f9fbfd;
  height: 60px;
  width: 100%;
  padding-top: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-pack: center;
  justify-content: center;
}

.framealive-actions .action {
  cursor: pointer;
  margin: 0 auto;
  padding: 2px 10px;
  width: 120px;
  height: 25px;
  margin: 0 5px;
  text-align: center;
  border: 1px solid gray;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bolder;
}

.framealive-actions .action:hover {
  background-color: gray;
  color: white;
}

.framealive-actions .action a {
  color: inherit;
}

.framealive-actions .action a:hover, .framealive-actions .action a:visited {
  color: inherit;
  text-decoration: none;
}

.framealive-pagination-actions {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  padding-top: 45px;
  font-size: 12px;
}

.framealive-pagination-actions .pagination-button {
  margin: 0 10px;

  cursor: pointer;
}

.framealive-pagination-actions .pagination-button i {
  padding: 0 5px;
}

.framealive-list {  }

.framealive-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  padding: 10px;
  height: 90px;
  border: 1px solid transparent;
  border-bottom: 1px solid #e9ecef;
  box-sizing: border-box;
}

.framealive-item:hover {
  background-color: #f8f9fa;
}

.framealive-item.selected {
  border: 1px solid #18ceb8;
  background-color: #f6fcfb;
}

.framealive-thumbnail {
  flex: 0.2;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.framealive-thumbnail img {
  width: 100px;
  max-height: 70px;
  border-radius: 5px;
  cursor: pointer;
}

.framealive-files {
  flex: 0.3;

  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 12px;
}

.framealive-image-file {

}

.framealive-video-file {

}

.framealive-details {
  flex: 0.2;

  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 12px;
}

.framealive-creation-date {

}

.framealive-match-count {

}

.framealive-value-icon {
  margin-right: 7px;
  font-size: 12px;
  color: #343a40;
}

.framealive-download-icon {
  float: right;
}

.framealive-item-actions {
  flex: 0.05;

  display: flex;
  flex-direction: column;
  justify-content: center;

  font-size: 12px;
}

/*
 * Delete confirmation popup
 */

.delete-confirmation {
  display: flex;
  flex-direction: column;
  height: 170px;
}

.delete-message {
  flex: 0.3;
  text-align: center;
  font-weight: bold;
}

.delete-fa-details {
  flex: 0.5;
  margin-left: 20px;
}

.delete-fa-details .field {
  font-weight: bold;
  margin-right: 20px;
  display: inline-block;
  width: 50px;
}

.delete-actions {
  flex: 0.2;
  display: flex;
  justify-content: space-around;
}

/*
 * Footer
 */

footer {
  background-color: white;
}

footer .bottomlileft {
  font-size: 12px;
}

footer .nav-link {
  color: #343a40;
}

footer .nav-item i {
  color: #343a40;
}

/*
 * Buttons
 */

.simple-btn {
  margin: 0 auto;
  padding: 5px 10px;
  text-align: center;

  border: 1px solid gray;
  border-radius: 3px;

  font-weight: bolder;

  cursor: pointer;
}

.simple-btn:hover {
  background-color: gray;
  color: white;
}


/*
 * Terms and Priviacy policy
 */

.text-container {
  max-width: 700px;
  margin: 0 auto;
  margin-top: 120px;
  margin-bottom: 50px;
  padding: 5px;
  text-align: justify;
}

.text-container h5 {
  font-weight: 400;
  margin-bottom: 20px;
}

.scanHorizontal{
  display: flex;
  flex-direction: row;
  width: 100%;
}

.scanVertical{
  position: relative;
  top:0px;
  z-index: 2;
  background-color: orange;
}

.scanAngule{
/*  display:flex;
  flex-grow:0.3;

  justify-content: center;
  align-items: center;*/
  height: 60px;
  background-color: black;
  text-align:center;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 1;
  vertical-align: middle;
}
.scanAngule2{

  width: 25px;
  height: 25px;
  z-index: 1;
}
.webcamStyle{
  /*display:flex;
  flex-grow:0.7;*/
  z-index: 1;
  vertical-align: middle;
  background-color: blue;
}

.scanGlobal{
  /*display:flex;
  flex-direction: column;*/
  vertical-align: middle;
  height: 100%;



}

.scanButton{
  color:white;
  border: 2px solid white;
  border-radius: 5px;
  background-color:transparent;
  height: 50px;
  width: 70px;
}

.player-wrapper {
  position: relative;
  /*padding-top: 56.25%;  Player ratio: 100 / (1280 / 720) */
  z-index:2;
}

.react-player {
  position: fixed;
  top: 0;
  left: 0;
  background-color:black;
}

#inner-circle{
  border-radius: 0% !important;
  display:none;
}

#outer-circle{
  background-image: url("../static/img/playIos.png");
  background-size: 200px;
  background-repeat: no-repeat;
  border-radius: 0% !important;
  background-color:transparent;
  width: 200px;
  height: 200px;
  top:50%;
  left:50%;
  margin-left: -100px;
  margin-top: -100px;
  position: fixed;
  opacity: 0.6;
}

#container-circles{
  position:relative !important;
}

.loading{
  background-image: url("../static/img/loading.gif");
  background-size: 200px;
  background-repeat: no-repeat;
  border-radius: 0% !important;
  background-color:transparent;
  width: 200px;
  height: 200px;
  top:50%;
  left:50%;
  margin-left: -100px;
  margin-top: -100px;
  position: fixed;
  opacity: 0.6;
  border:none;
  z-index: 8;
}

.notRecognizeImage{
  position: fixed;
  top:80px;
  left:0px;
  color:white;
  font-size: 28px;
  font-weight: bold;
  text-align:center;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

.tryAgainMsg{
  position: fixed;
  bottom:80px;
  left:0px;
  color:white;
  font-size: 22px;
  text-align:center;
  padding-left: 30px;
  padding-right: 30px;
  width: 100%;
}

@media screen and (orientation:landscape) {

  .tryAgainMsg{
    bottom:65px;
    padding-left: 50px;
    padding-right: 50px;
  }

  .notRecognizeImage{
    bottom:65px;
    padding-left: 50px;
    padding-right: 50px;
  }

 }
