/*!
 * Start Bootstrap - Createive v4.0.0-beta (http://startbootstrap.com/template-overviews/creative)
 * Copyright 2013-2017 Start Bootstrap
 * Licensed under MIT (https://github.com/BlackrockDigital/startbootstrap-creative/blob/master/LICENSE)
 */

body,
html {
    width: 100%;
    height: 100%;
}
body {} hr {
    max-width: 50px;
    border-width: 3px;
    border-color: #F05F40;
}
hr.light {
    border-color: white;
}
a {
    color: white;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
}
a:focus,
a:hover {
    color: white;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Roboto", sans-serif;
}
p {
    font-size: 16px;
    line-height: 1.2;
    margin-bottom: 20px;
}
.bg-primary {
    background-color: #F05F40 !important;
}
.bg-dark {
    color: white;
    background-color: #222222 !important;
}
.text-faded {
    color: rgba(255, 255, 255, 0.7);
}
section {
    padding: 100px 0;
}
.section-heading {
    margin-top: 0;
}
::-moz-selection {
    color: white;
    background: #222222;
    text-shadow: none;
}
::selection {
    color: white;
    background: #222222;
    text-shadow: none;
}
img::selection {
    color: white;
    background: transparent;
}
img::-moz-selection {
    color: white;
    background: transparent;
}
body {
    -webkit-tap-highlight-color: #222222;
}

#root {
  height: 100%;
}

.app {
  height: 98%;
}

#mainNav {
    border-color: rgba(34, 34, 34, 0.05);
    background-color: white;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    transition: all 0.2s;
    min-height: 70px;
}
#mainNav .navbar-toggle {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #222222;
}
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus,
#mainNav .navbar-nav > li.nav-item > a.nav-link {
    color: #222222;
}
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover,
#mainNav .navbar-nav > li.nav-item > a.nav-link:hover {
    color: #F05F40;
}
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active,
#mainNav .navbar-nav > li.nav-item > a.nav-link.active {
    color: #F05F40 !important;
    background-color: white;
}
#mainNav .navbar-nav > li.nav-item > a.nav-link:focus.active:hover,
#mainNav .navbar-nav > li.nav-item > a.nav-link.active:hover {
    background-color: white;
}
@media (min-width: 992px) {
    #mainNav {
        border-color: rgba(255, 255, 255, 0.3);
        background-color: white;
    }
    #mainNav .navbar-brand {
        color: rgba(255, 255, 255, 0.7);
    }
    #mainNav .navbar-brand:focus,
    #mainNav .navbar-brand:hover {
        color: white;
    }
    #mainNav .navbar-nav > li.nav-item > a.nav-link,
    #mainNav .navbar-nav > li.nav-item > a.nav-link:focus {
        color: black;
        font-family: "Roboto", sans-serif;
        font-size: 17px;
    }
    #mainNav .navbar-nav > li.nav-item > a.nav-link:hover,
    #mainNav .navbar-nav > li.nav-item > a.nav-link:focus:hover {
        color: black;
    }
    #mainNav.navbar-shrink {
        border-color: rgba(34, 34, 34, 0.05);
        background-color: white;
    }
    #mainNav.navbar-shrink .navbar-brand {
        font-size: 16px;
        color: #F05F40;
    }
    #mainNav.navbar-shrink .navbar-brand:focus,
    #mainNav.navbar-shrink .navbar-brand:hover {
        color: #f05f40;
    }
    #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link,
    #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:focus {
        color: #222222;
    }
    #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:hover,
    #mainNav.navbar-shrink .navbar-nav > li.nav-item > a.nav-link:focus:hover {
        color: #F05F40;
    }
}
header.masthead {
    position: relative;
    width: 100%;
    min-height: auto;
    text-align: center;
    color: white;
    background-image: url("../static/img/fuegos.mp4");
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
header.masthead .header-content {
    position: relative;
    width: 100%;
    padding: 150px 15px 100px;
    text-align: center;
}
header.masthead .header-content .header-content-inner h1 {
    margin-top: 25%;
    margin-bottom: 0;
}
header.masthead .header-content .header-content-inner hr {
    margin: 30px auto;
}
header.masthead .header-content .header-content-inner p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 50px;
    color: white;
}
@media (min-width: 768px) {
    header.masthead {
        height: 100%;
        min-height: 600px;
    }
    header.masthead .header-content {
        position: absolute;
        top: 80px;
        padding: 0 50px;
    }
    header.masthead .header-content .header-content-inner {
        max-width: 1000px;
        margin-right: auto;
        margin-left: 2%;
    }
    header.masthead .header-content .header-content-inner h1 {
        font-size: 60px;
        font-family: "Roboto", sans-serif;
    }
    header.masthead .header-content .header-content-inner h1 div {
        font-size: 60px;
        font-family: "Roboto", sans-serif;
    }
    header.masthead .header-content .header-content-inner p {
        font-size: 17px;
        font-family: "Roboto", sans-serif;
        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
}
.btn-default {
    color: #222222;
    border-color: white;
    background-color: white;
}
.btn-default.focus,
.btn-default:focus {
    color: #222222;
    border-color: #bfbfbf;
    background-color: #e6e6e6;
}
.btn-default:hover {
    color: #222222;
    border-color: #e0e0e0;
    background-color: #e6e6e6;
}
.btn-default.active,
.btn-default:active,
.open > .btn-default.dropdown-toggle {
    color: #222222;
    border-color: #e0e0e0;
    background-color: #e6e6e6;
}
.btn-default.active.focus,
.btn-default.active:focus,
.btn-default.active:hover,
.btn-default:active.focus,
.btn-default:active:focus,
.btn-default:active:hover,
.open > .btn-default.dropdown-toggle.focus,
.open > .btn-default.dropdown-toggle:focus,
.open > .btn-default.dropdown-toggle:hover {
    color: #222222;
    border-color: #bfbfbf;
    background-color: #d4d4d4;
}
.btn-default.active,
.btn-default:active,
.open > .btn-default.dropdown-toggle {
    background-image: none;
}
.btn-default.disabled.focus,
.btn-default.disabled:focus,
.btn-default.disabled:hover,
.btn-default[disabled].focus,
.btn-default[disabled]:focus,
.btn-default[disabled]:hover,
fieldset[disabled] .btn-default.focus,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:hover {
    border-color: white;
    background-color: white;
}
.btn-default .badge {
    color: white;
    background-color: #222222;
}
.btn-primary {
    color: white;
    border-color: black;
    background-color: transparent;
}
.btn-primary.focus,
.btn-primary:focus {
    color: white;
    border-color: black;
    background-color: transparent;
}
.btn-primary:hover {
    color: black;
    border-color: white;
    background-color: white;
}
.btn-primary.active,
.btn-primary:active,
.open > .btn-primary.dropdown-toggle {
    color: white;
    border-color: #e13612;
    background-color: #eb3812;
}
.btn-primary.active.focus,
.btn-primary.active:focus,
.btn-primary.active:hover,
.btn-primary:active.focus,
.btn-primary:active:focus,
.btn-primary:active:hover,
.open > .btn-primary.dropdown-toggle.focus,
.open > .btn-primary.dropdown-toggle:focus,
.open > .btn-primary.dropdown-toggle:hover {
    color: white;
    border-color: black;
    background-color: black;
}
.btn-primary.active,
.btn-primary:active,
.open > .btn-primary.dropdown-toggle {
    background-image: none;
}
.btn-primary.disabled.focus,
.btn-primary.disabled:focus,
.btn-primary.disabled:hover,
.btn-primary[disabled].focus,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:hover,
fieldset[disabled] .btn-primary.focus,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:hover {
    border-color: #F05F40;
    background-color: #F05F40;
}
.btn-primary .badge {
    color: #F05F40;
    background-color: white;
}
.btn {
    border: solid;
    border-width: 1px;
    border-radius: 300px;
    font-family: "Roboto", sans-serif;
    font-size: 17.7px;
}
.btn-xl {
    padding: 15px 60px;
}
.bottomulleft {
    margin-left: 10%;
    list-style: none;
    display: list-item;
}
.bottomlileft {
    float: left;
    margin: 10px 0px 10px 10px;
    font-size: 17px;
    font-family: "Roboto", sans-serif;
}
.bottomulright {
    margin-right: 10%;
    list-style: none;
    display: list-item;
}
.bottomliright {
    float: right;
    margin: 10px 10px 5px 15px;
}
.btnLogin {
    color: white;
    background-color: black;
    border-radius: 300px;
    padding: 4px 35px;
    border:1px solid black;
}
.superMenu {
    padding-top: 13px;
}
.btnPremium {
    color: white;
    border-radius: 300px;
    padding: 10px 50px;
    border-color: white;
}
.rightcontent {
    text-align: left;
    align-content: left;
    padding-left: 5%;
}
.list-group {
    margin-top: 40%;
}
/*.list-group-item {*/

    .backgroundVideo {
        background-image: url("../static/img/video.png");
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        border: solid 5px transparent;
        background-origin: border-box;
    }
    .backgroundPhoto {
        background-image: url("../static/img/image.png");
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;

        border: solid 5px transparent;
        background-origin: border-box;
    }

    .dropzone-active.backgroundVideo {
        border: solid 5px rgb(151, 214, 197);
    }

    .dropzone-active.backgroundPhoto {
        border: solid 5px rgb(151, 214, 197);
    }

    .leftTitle {
        padding-left: 20%;
    }
    .subMenu {
        color: black;
    }
    .subMenu:hover {
        color: black;
    }
    header.masthead .header-content .header-content-inner h1 div p {
        font-size: 60px;
        font-family: "Roboto", sans-serif;
        font-weight: bold;
    }
    header.masthead .header-content .header-content-inner div p {
        font-family: "Roboto", sans-serif;
        margin-bottom: 0%;
        font-size: 20px;
    }
    .uploaderRes2 {
        margin-left: 350px;
        padding-top: 1px;
        margin-top: -60%;
    }
    .miniLogos {
        color: black;
    }
    .shadow {
        box-shadow: 15px 15px 15px rgba(34, 34, 34, 0.41);
        border-radius: 20px;
    }
    .shadowVideo {
        box-shadow: 15px 15px 15px rgba(34, 34, 34, 0.41);
    }
    /*Inicio Backend*/

    @media (min-width: 425px) {
        .btnPremium {
            color: black;
            border-radius: 300px;
            padding: 10px 50px;
            border-color: black;
        }
        .btnPremium:hover {
            color: white;
            border-radius: 300px;
            padding: 10px 50px;
            border-color: white;
            margin-left: 10%;
            background-color: black;
        }
    }
    @media (min-width: 768px) {
        header.masthead .header-content .header-content-inner p {
            font-size: 16px;
            font-weight: 300;
            margin-bottom: 50px;
            color: black;
        }
    }

    @media (max-width: 820px) and (min-width: 655px) {
        /*Corregir uploader y texto*/

        .uploaderRes1 {
            padding-top: 5%;
            padding-left: 1%;
        }
        .uploaderRes2 {
            padding-left: 30%;
            padding-top: 1px;
            margin-top: -80%;
        }
    }
    @media (max-width: 654px) and (min-width: 300px) {
        /*Corregir uploader y texto*/

        .uploaderRes1 {
            margin-left: 15%;
        }
        .uploaderRes2 {
            display: none;
        }
        .leftcontent {
            align-content: right;
            width: 70%;
        }
    /*.row{
    margin-top: -12%;
    }*/

    nav div a img {
        width: 190px;
    }
    header.masthead .header-content .header.demomasthead {
        position: relative;
        width: 100%;
        padding: 150px 15px 100%;
        text-align: center;
    }
    .noShow {
        display: none;
    }
}
@media (max-width: 768px) {
    .list-group {
        margin-top: 0px;
    }
    .row {
        margin-top: -70px;
    }

    .bottomulleft {
        margin-left: 1%;
    }

    .bottomulright {
        margin-right: 1%;
    }

}
@media (max-width: 425px) {
    header.masthead .header-content {
        position: relative;
        width: 100%;
        padding: 150px 15px 100px;
        text-align: center;
        height: -webkit-stretch;
    }
}
header.demomasthead {
    position: relative;
    width: 100%;
    min-height: auto;
    text-align: center;
    color: black;
}
header.demomasthead .header-content {
    position: relative;
    width: 100%;
    padding: 0 15px 100px;
    margin-top: 70px;
    text-align: center;
}
header.demomasthead .header-content .header-content-inner h1 {
    margin-top: 20%;
    margin-bottom: 0;
}
header.demomasthead .header-content .header-content-inner hr {
    margin: 30px auto;
}
header.demomasthead .header-content .header-content-inner p {
    font-size: 16px;
    font-weight: 300;
    margin-bottom: 0px;
    color: black;
    margin-left: auto;
    margin-right: auto;
}
@media (min-width: 768px) {
    header.demomasthead {
        height: 100%;
        min-height: 600px;
    }
    header.demomasthead .header-content {
        position: absolute;
        margin-top: 80px;
        padding: 0 50px;
    }
    header.demomasthead .header-content .header-content-inner {
        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
    header.demomasthead .header-content .header-content-inner h1 {
        font-size: 50px;
        font-family: "Roboto", sans-serif;
    }
    header.demomasthead .header-content .header-content-inner h1 div {
        font-size: 60px;
        font-family: "Roboto", sans-serif;
    }
    header.demomasthead .header-content .header-content-inner p {
        font-size: 17px;
        font-family: "Roboto", sans-serif;
        max-width: 80%;
        margin-right: auto;
        margin-left: auto;
    }
}
.shadowHeader {
    box-shadow: 2px 2px 5px #999;
}
.list-group-item:first-child {
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    height: auto;
}
.list-group-item:first-child.noborder {
    border-top-left-radius: 0rem;
    border-top-right-radius: 0rem;
}
.list-group-item:last-child.noborder {
    margin-bottom: 0;
    border-bottom-right-radius: 0rem;
    border-bottom-left-radius: 0rem;
}

.circuloPrecio {
    width: 75px;
    height: 70px;
    display: table;
    margin: auto;
    border-radius: 100%;
    cursor: pointer;
    /*box-shadow:  0 5px 20px rgba(0,0,0,.1);*/

    box-shadow: 0pt 0pt 6pt rgba(0, 0, 0, 0.28);
    filter: progid: DXImageTransform.Microsoft.dropshadow(OX=0, OY=0, Color='#000000');
}
.circuloPrecio:hover {
    box-shadow: inset 0 5px 30px rgba(0, 0, 0, .2);
    background-size: 5.9em;
}
.circuloEspecial{
    margin-top: -17px;
    width: 100px;
    height: 70px;
}

.textoPrecio {
    padding-top: 25px;
    padding-bottom: 20px;
    width: 60px;
}
.noBorderLine {
    border: none;
    /*background-color: rgba(245, 245, 245, 1);*/
    background-color: rgb(255, 255, 255);
}
.technicalData {
    height: 130px;
    margin-bottom: -15px;
    border-width: 1px;
    border-color: white;
    border-style: solid;
    background-color: white;
    border-radius: 3pt;
    -moz-border-radius: 3pt;
    -webkit-border-radius: 3pt;
    box-shadow: 0pt 7pt 6pt rgba(0, 0, 0, 0.28);
    filter: progid: DXImageTransform.Microsoft.dropshadow(OX=0, OY=7, Color='#000000');
}
.btnBuy {
    color: white;
    background-color: black;
    border-radius: 200px;
    padding: 10px 35px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    position: inherit;
    text-decoration-style: none;
    box-shadow: 0pt 7pt 6pt rgba(0, 0, 0, 0.28);
    filter: progid: DXImageTransform.Microsoft.dropshadow(OX=0, OY=7, Color='#000000');
}
.btnBuy:hover {
    color: white;
    background-color: #4f4f4f;
    border-radius: 200px;
    padding: 10px 35px;
    width: 100px;
    margin-left: auto;
    margin-right: auto;
    position: inherit;
    box-shadow: 0pt 7pt 6pt rgba(0, 0, 0, 0.28);
    filter: progid: DXImageTransform.Microsoft.dropshadow(OX=0, OY=7, Color='#000000');
}
.noDecoration {
    text-decoration: none;
}

.btnUpload {
    color: black;
    background-color: white;
    border-radius: 200px;
    padding: 7px 40px;
    border-color: black;
    margin-top: 15px;
}
.btnUpload:hover, .btnUpload:focus {
    color: white;
    background-color: black !important;
    border-radius: 200px;
    border-color: white;
}
@media (min-width: 768px) {
    .container-fluid{
       margin-top: 66px;
   }
}
@media (min-width: 2250px) {
    header.masthead .header-content .header-content-inner h1 div p {
        font-size: 100px;
        font-family: "Roboto", sans-serif;
        font-weight: bold;
    }
    header.masthead .header-content .header-content-inner div p {
        font-family: "Roboto", sans-serif;
        margin-bottom: 0%;
        font-size: 30px;
    }
    .leftcontent {
        align-content: right;
        width: 396px;
        height: 666px;
        border-radius: 24pt;
        -moz-border-radius: 24pt;
        -webkit-border-radius: 24pt
    }
    .uploaderRes2 {
        margin-left: -500px;
        margin-top: 400px;
        padding-left: 0px;
    }
    .shadow {
        width: 396px;
        height: 595px;
        border-radius: 24px;
        -moz-border-radius: 24px;
        -webkit-border-radius: 24px;
        box-shadow: 15px 15px 15px rgba(34, 34, 34, 0.41);
    }
    .selFile {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 27px;
        text-align: center;
    }
    .list-group-item:last-child {
        border-style: Solid;
        border-color: #ADADAD;
        border-color: rgba(173, 173, 173, 1);
        border-width: 1px;
        border-left: none;
        border-right: none;
        border-bottom: none;
        height: 100px;
    }
    .btnUpload {
        font-family: "Roboto", sans-serif;
        font-size: 23px;
        color: #0D0810;
        color: rgb(13, 8, 16);
        width: 232px;
        height: 48px;
        border-radius: 24px;
        -moz-border-radius: 24px;
        -webkit-border-radius: 24px;
        border-style: Solid;
        border-color: #0D0810;
        border-color: rgba(13, 8, 16, 1);
        border-width: 1px;
        margin-bottom: 63px;
        margin-left: 20px;
        margin-right: 55px;
    }
    .textSelect {
        font-family: "Roboto", sans-serif;
        font-size: 24px;
        line-height: 1.5;
        margin-bottom: 0;
    }
    .list-group-item:first-child {
        height: 83px;
    }
    .backgroundVideo {
        background-image: url("../static/img/video.png");
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 398px;
        height: 181px;
    }
    .backgroundPhoto {
        background-image: url("../static/img/image.png");
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
        width: 398px;
        height: 181px;
    }
    .circleUpload {
        background-image: url("../static/img/circle.png");
        background-repeat: no-repeat;
        height: 47px;
        width: 47px;
        display: inline-block;
    }
    .circleUpload:hover {
        background-image: url("../static/img/circleHover.png");
        background-repeat: no-repeat;
        height: 47px;
        width: 47px;
        display: inline-block;
    }
    .leftTitle {
        padding-left: 95px;
    }
    .miniLogos {
        color: black;
        font-size: 1.6em;
        margin-left: -17px;
    }
    .txtHomeBig {
        font-family: "Roboto", sans-serif;
        font-size: 80px;
    }
    .txtHomeSmall {
        font-family: "Roboto", sans-serif;
        font-size: 60px;
    }
    .plusImage {
        padding-left: 135px;
    }
    .list-group {
        margin-left: 100px;
        margin-top: 300px;
    }
    .row {
        margin-top: -175px;
    }
    /*End 2250*/
}
@media (max-width: 2250px) and (min-width: 768px) {
    .selFile {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 20px;
        text-align: center;
    }
    .list-group-item:first-child {
        height: 54px;
    }
    .btnPremium {
        margin-left: 0px;
    }
    .btnPremium:hover {
        margin-left: 0px;
    }
    .uploaderRes1 {
        position: absolute;
        width: 326px;
        height: 750px;
        border-radius: 24px;
        -moz-border-radius: 24px;
        -webkit-border-radius: 24px;
    }
    .plusImage {
        padding-left: 96px;
        margin-top: -10px;
        margin-bottom: 10px;
    }
    .miniLogos {
        color: black;
        font-size: 1.3em;
        margin-left: -17px;
    }
    .leftTitle {
        padding-left: 55px;
    }
    .textSelect {
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        margin-bottom: 5px;
        margin-top: -5px;
        line-height: 1;
    }
    .list-group-item:first-child {
        height: 65px;
        padding-top: 17px;
        padding-bottom: 19px;
    }
    .circleUpload {
        background-image: url("../static/img/circle.png");
        background-repeat: no-repeat;
        height: 47px;
        width: 47px;
        position: absolute;
    }
    .circleUpload:hover {
        background-image: url("../static/img/circleHover.png");
        background-repeat: no-repeat;
        height: 47px;
        width: 47px;
        position: absolute;
    }
    .btnUpload {
        border-style: Solid;
        border-color: #0D0810;
        border-color: rgba(13, 8, 16, 1);
        border-width: 1px;
        width: 149px;
        border-radius: 60px;
        -moz-border-radius: 60px;
        -webkit-border-radius: 60px;
        font-family: "Roboto", sans-serif;
        font-size: 15px;
        color: #0D0810;
        color: rgb(13, 8, 16);
        margin-left: 60px;
        margin-top: 0px;
    }
    .list-group-item {
        color: black;
        /*padding-top: 16px;
        padding-bottom: 16px;*/
        padding-left: 15px;
    }
    .uploaderRes2 {
        /*margin-left: 30px;*/
        margin-top: 110px;
    }
    .txtHomeBig {
        font-family: "Roboto", sans-serif;
        font-size: 63px;
    }
    .txtHomeSmall {
        font-family: "Roboto", sans-serif;
        font-size: 20px;
    }
    /*end media (max-width: 2250px) and (min-width: 768px)*/
}
@media (max-width: 1115px) and (min-width: 768px) {
    .btnPremium {
        margin-left: 0px;
    }
    .uploaderRes1 {
        position: absolute;
        height: 429px;

        border-radius: 24px;
        -moz-border-radius: 24px;
        -webkit-border-radius: 24px;
    }
    .plusImage {
        padding-left: 70px;
        margin-top: -10px;
        margin-bottom: 10px;
    }
    /*end media (max-width: 900px) and (min: 320px)*/
}
@media (max-width: 768px) and (min-width: 320px) {
    .selFile {
        font-family: "Roboto", sans-serif;
        font-weight: bold;
        font-size: 17px;
        text-align: center;
        text-align: center;
    }
    .uploaderRes1 {
        position: absolute;
        margin-left: 0px;
        border-radius: 24px;
        -moz-border-radius: 24px;
        -webkit-border-radius: 24px;
        width: 100%;
    height: auto;
    padding: 0;
    margin-top: 75px;
    }
    .plusImage {
        padding-left: 30px;
    }
    .miniLogos {
        color: black;
        font-size: 1.3em;
        margin-left: -17px;
    }
    .leftTitle {
        padding-left: 55px;
    }
    .textSelect {
        font-family: "Roboto", sans-serif;
        font-size: 17px;
    }
    .circleUpload {
        background-image: url("../static/img/circle.png");
        background-repeat: no-repeat;
        height: 47px;
        width: 47px;
        position: absolute;
    }
    .circleUpload:hover {
        background-image: url("../static/img/circleHover.png");
        background-repeat: no-repeat;
        height: 47px;
        width: 47px;
        position: absolute;
    }
    .btnUpload {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
        color: #0D0810;
        color: rgb(13, 8, 16);
        border-style: Solid;
        border-color: #0D0810;
        border-color: rgba(13, 8, 16, 1);
        border-width: 1px;
        width: 165px;
        border-radius: 45px;
        -moz-border-radius: 45px;
        -webkit-border-radius: 45px;
    }
    .list-group-item {
        color: black;
        padding-left: 15px;
    }
    .uploaderRes2 {
        margin-left: 125px;
    }
    .txtHomeBig {
        font-family: "Roboto", sans-serif;
        font-size: 43px;
    }
    .txtHomeSmall {
        font-family: "Roboto", sans-serif;
        font-size: 16px;
    }
    .list-group-item:last-child {
        border-style: none;
        border-color: #ADADAD;
        border-color: rgba(173, 173, 173, 1);
        border-width: 1px;
        width: 295px;
        max-width: 100%;
        height: 76px;
        border-left: none;
        border-right: none;
        border-bottom: none;
    }
    .leftcontent {
        position: absolute;
        top: 60px;
        width: 282px;
        height: 474px;
        border-radius: 16px 17px 17px 16px;
        -moz-border-radius: 16px 17px 17px 16px;
        -webkit-border-radius: 16px 17px 17px 16px;
    }
    .list-group {
        width: 295px;
        max-width: 100%;
    }
    .plusImage {
        padding-left: 90px;
        margin-bottom: 10px;
        margin-top: -10px;
    }
    .btnUpload {
        margin-left: 85px;
        margin-top: 0px;
    }
    .selFile {
        padding-left: 5px;
        text-align: center;
        text-align: center;
    }
    .btnPremium {
        margin-left: 0px;
    }
    /*end media (max-width: 2250px) and (min-width: 768px)*/
}
@media (max-width: 425px) {
    .bottomlileft {
        float: left;
        margin: 0px 0px 0px 0px;
        font-size: 17px;
        font-family: "Roboto", sans-serif;
    }
    .fa-3x {
        font-size: 1em;
    }
    header.masthead {
        position: relative;
        width: 100%;
        min-height: auto;
        text-align: center;
        color: white;
        background-image: url("../static/img/header.jpg");
        background-position: center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }
}
#video_background {
    position: absolute;
    bottom: 0%;
    right: 0px;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    z-index: -1000;
    /*overflow: hidden;*/
    opacity: 0.35;
    filter: alpha(opacity=35);
    top: 6%;
}
#video_pattern {
    background: #fff;
    position: fixed;
    opacity: 0.8;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 1;
}
.camera {
    /*background-image: url("../static/img/camera.png");
  background-position: center;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 250px;
  margin-top: 20px;*/

  width: -webkit-stretch;
}
.book {

  width: -webkit-stretch;
}
.frame {

  width: -webkit-stretch;
}
.rightText {
    text-align: right;
    /*margin: -15% 0 0 -25%;*/
}
.leftText {
    text-align: left;
    margin-top: auto;
    margin-bottom: auto;
}
.rowAbout {
    margin-top: 120px;
    margin-bottom: 50px;
    margin-left: auto;
    margin-right: auto;
}
.specialText {
    text-align: left;
    margin-bottom: 80px;
    margin-top: 80px;
}
.specialAbout {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color:black;
}
.contLogin {
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    height: 500px;
    padding-top: 150px;
    border-radius: 21pt;
    -moz-border-radius: 21pt;
    -webkit-border-radius: 21pt;
    position: absolute;
    margin-top: 13%;
    margin-left: 15%;
    box-shadow: 0px 0px 10px 2px rgb(188, 185, 185);
    font-family: "Roboto", sans-serif;
}
.userLogin {
    font-family: "Roboto", sans-serif;
    font-size: 45px;
    font-weight: 100;
}
.backgroundImg {
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    min-height: auto;
    text-align: center;
    color: black;
    height: 100%;
    background-image: url("../static/img/login-background.jpg");
    margin-top: 11px;
    background-position: center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}
.rowLogin {
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
}
.iconoUser {
    display: -webkit-inline-box;
    margin-left: -30px;
}
.btnLogin2 {
    margin-left: 15px;
    width: 311px;
    height: 42px;
    border-radius: 21px;
    -moz-border-radius: 21px;
    -webkit-border-radius: 21px;
    background-color: black;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #FFFFFF;
    color: rgb(255, 255, 255);
    font-weight: 100;
    border-width: 0px;
    border-style: outset;
    border-color: buttonface;
    border-top-color: buttonface;
    border-right-color: buttonface;
    border-bottom-color: buttonface;
    border-left-color: buttonface;
    margin-top: 10px;
}
.newAcount {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    color: #0E0A10;
    color: rgb(14, 10, 16);
    font-weight: 100;
}
.inputLogin {
    padding-left: 25px;
    padding-right: 25px;
    border-style: Solid;
    border-color: #0E0A10;
    border-color: rgba(14, 10, 16, 1);
    border-width: 1px;
    width: 311px;
    height: 42px;
    border-radius: 21px;
    -moz-border-radius: 21px;
    -webkit-border-radius: 21px;
    margin-top: 10px;
}
@media(max-width: 3000px) and (min-width: 925px) {
    .contLogin {
        margin-left: auto;
        background-color: white;
        width: 994px;
        height: 546px;
        padding-top: 110px;
        border-radius: 9pt;
        -moz-border-radius: 9pt;
        -webkit-border-radius: 9spt;
        position: absolute;
        margin-top: 13%;
        box-shadow: 0px 0px 10px 2px rgb(188, 185, 185);
        font-family: "Roboto", sans-serif;
        position: absolute;
    }
}
@media (max-width: 924px) {
    .contLogin {
     margin: 0 auto;
     background-color: white;
     height: auto;
     padding: 25px 15px 25px 15px;
     -moz-border-radius: 21pt;
     -webkit-border-radius: 21pt;
     box-shadow: 0px 0px 10px 2px rgb(188, 185, 185);
     font-family: "Roboto", sans-serif;
     position: absolute;
     margin-top: 25%;
 }

 .userLogin{
    font-family: "Roboto", sans-serif;
    font-size: 38px;
    font-weight: 100;
}

.newAcount {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    color: #0E0A10;
    color: rgb(14, 10, 16);
    font-weight: 100;
}

.inputLogin,
.btnLogin2{
    width:70%;
    margin-top: 10px;
}
}
@media (min-width: 1200px) {
    .container {
        max-width: 90%;
    }
}

.noShowLogin{
    display: none;
}

#headerPrice{
    font-family: "Roboto", sans-serif;
    font-size: 20pt;
    font-weight: bold;
    margin-left: auto!important;
    margin-right: auto;
}



.btnLogin:hover{
    background-color:white;
    color:black;
    cursor: pointer;
}

.landing{
    max-height: 88vh;
    overflow: auto;
}

.row{
    margin:0;
}

#pie,
footer{
    background-color: black;
    position: fixed;
    bottom: 0;
    margin-right: auto;
    margin-left: auto;
    padding-right: 15px;
    padding-left: 15px;
    width: 100%;
}

/*MyVideos.html*/

.videoThumbnailContainer{
    width: 100%;
    padding-top: 35%;
}

@media (max-width: 860px){
    .videoThumbnailContainer{
    width: 100%;
    padding-top: 40%;
}
}

.videoThumbnail{
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 235px;
    height: auto;
    border-radius:15px;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 235px;
    height: 176px
}

.share{
    margin-top: 20px;
    margin-left: 15px;
    color: black;
    display: block;
}

/*About*/
.aboutTitle{
    font-family: "Roboto", sans-serif;
    font-size: 40px;
    color:black;
}

.specialAbout a,
.specialAbout a:link,
.specialAbout a:visited{
    color:black;
}

.rowAboutComp{
    padding-bottom: 50px;
}

@media(max-width: 1370px){
    .camera img,
    .frame img,
    .book img{
        max-width: 100%;
    }
}

@media(max-width: 768px){
    .rowAboutComp .leftText{
        margin-top: 30px;
    }

    footer{
        padding-left:0px;
        padding-right:0px;
    }
    .bottomlileft{
        font-size:15px;
    }
    .bottomliright {
        margin: 5px 5px 4px 5px;
    }
}

@media(max-width: 426px){
    .uploaderRes1{
        width: 100%;
        height: 600px;
        padding: 0;
        margin-top: 75px;
        margin-bottom: 150px;
    }

    .container-fluid{
        padding-left: 0px;
        padding-right: 0px;
    }
    .uploaderRes1 ul.list-group{
        margin: 0 auto;
    }
    .textSelect{
        margin-bottom: 10px;
        margin-top: -10px;
    }
    .col-lg-3{
        margin: 10px;
    }
    .play .fa-3x{
        font-size: 3em;
    }
}

.imageAbout{
    width: 369px;
}

.txtFAQ{
    margin-top: 100px;
    margin-bottom: 60px;
    text-align: left;
}

.txtDemo{
    font-family: "Roboto", sans-serif;
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.rowDemo{
    margin-top: 120px;
    margin-bottom: 60px;
    text-align: center;
}

.rowDemoMedium{
    text-align: center;
}

.rowDemoFinish{
    margin-bottom: 40px;
    text-align: center;
}

.rowDemoButton{
    margin-bottom: 200px;
    text-align: center;
}

@media (max-width: 890px){
    .noShowResponsive{
        display: none;
    }

    .rowDemoMedium{
        margin-top: 120px;
        text-align: center;
    }
}

@media (min-width: 1120px){
    .frameVideo{
        width: 855px;
    }
}

.prof{
    margin-left: 0px!important;
}

#btnContactUs{
    padding-left: 10px;
    padding-right: 10px;
    width: 70%;
}

.monthSubscription{
    font-size: 15px!important;
    margin-bottom: 10px!important;
    margin-top: -17px!important;
}

.contXG{
        padding: 5px!important;
}
.contXGE{
        padding: 2px!important;
}
.contXGB{
        padding: 5px!important;
}
/*Popup*/
#popup {
   visibility: hidden;
   opacity: 0;
   margin-top: 20%;
}
#popup:target {
   visibility:visible;
   opacity: 1;
   background-color: rgba(0,0,0,0.8);
   position: fixed;
   top:0;
   left:0;
   right:0;
   bottom:0;
   margin:0;
   z-index: 999;
   transition:all 1s;
}
#popupVideo {
   visibility: hidden;
   opacity: 0;
   margin-top: 20%;
}
#popupVideo:target {
   visibility:visible;
   opacity: 1;
   background-color: rgba(0,0,0,0.8);
   position: fixed;
   top:0;
   left:0;
   right:0;
   bottom:0;
   margin:0;
   z-index: 999;
   transition:all 1s;
}
.popup-contenedor {
   position: relative;
   margin:20% auto;
   padding:30px 50px;
   background-color: #fafafa;
   color:#333;
   border-radius: 5px;
   width:290px;
}
a.popup-cerrar {
   position: absolute;
   top:3px;
   right:3px;
   background-color: #333;
   padding:7px 10px;
   font-size: 20px;
   text-decoration: none;
   line-height: 1;
   color:#fff;
}

.video-background {
  background: #000;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -99;
  opacity: 0.7;
}
.video-foreground,
.video-background iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
